import { apiUtils } from '@/@core/utils/apiUtils'
import axiosIns from '@/libs/axios'
import * as moment from 'moment'
const controller = 'medical-appointment'

export const JOGO_ID = 1
export const JOGO_TESTE_ID = 3

export default {
  async getAll() {
    return axiosIns.get(controller).then(res => res.data.content)
  },
  async getByPatient(patientId) {
    return axiosIns.get(`${controller}?patientIds=${patientId}&pageSize=9999`).then(res => res.data.content)
  },
  async getRecentsPatientWithGameId(gameId) {
    const gameName = gameId == JOGO_ID ? 'KNAPDOWN' : 'Jogo Teste'
    return axiosIns
      .get(
        `${controller}?startDate=${moment().startOf('day').format('YYYY-MM-DDTHH:mm')}&games=${gameName}&pageSize=6&sort=startDate,desc`
      )
      .then(res => res.data.content)
  },
  async getRecentsPatient() {
    return axiosIns
      .get(
        `${controller}?endDate=${moment().format(
          'YYYY-MM-DDTHH:mm'
        )}&pageSize=6&sort=startDate,desc`
      )
      .then(res => res.data.content)
  },
  async getNextsPatient() {
    return axiosIns
      .get(
        `${controller}?startDate=${moment().format(
          'YYYY-MM-DDTHH:mm'
        )}&pageSize=6&sort=startDate,asc`
      )
      .then(res => res.data.content)
  },
  async getById(id) {
    return axiosIns.get(`${controller}/${id}`).then(res => res.data)
  },
  async update(obj) {
    return axiosIns.put(controller, obj).then(res => res.data)
  },
  async create(req) {
    return axiosIns.post(controller, req).then(res => res.data)
  },
  async getSchedule(params) {
    const paramsToSend =
      typeof params == 'string' ? params : apiUtils.objectToQueryParams(params)
    return axiosIns
      .get(`${controller}${paramsToSend}&size=99999`)
      .then(res => res.data)
  },
  async getFile(id, consultationType, req) {
    return axiosIns
      .get(
        `${controller}/document/medical-appointment/${id}/consultation-type/${consultationType}`,
        {}
    )
      .then(res => res.data)
  },
  async saveFiles(id, consultationType, req) {
    return axiosIns
      .post(
        `${controller}/documents/medical-appointment/${id}/consultation-type/${consultationType}`,
        req
      )
      .then(res => res.data)
  },
}
