import axiosIns from '@/libs/axios'

const controller = 'patient-history'

export default {
  async getAll() {
    return axiosIns.get(controller).then(res => res.data.content)
  },
  async getById(id) {
    return axiosIns.get(`${controller}/patient/${id}`).then(res => res.data)
  },
  async update(obj) {
    return axiosIns.put(controller, obj).then(res => res.data)
  },
  async create(req) {
    return axiosIns.post(controller, req).then(res => res.data)
  },
  async changePassword(req) {
    return axiosIns.post(`${controller}/change-password`, req).then(res => res.data)
  },
  getTypeOperationByOperation(operation) {
    switch (operation) {
      case 'NEW_MEDICAL_APPOINTMENT':
        return 'Consulta Agendada'
      case 'UPDATED_MEDICAL_APPOINTMENT':
        return 'Consulta Atualizada'
      case 'NEW_PATIENT':
        return 'Paciente Cadastrado'
      case 'UPDATED_PATIENT':
        return 'Paciente Atualizado'
      case 'CHANGE_PATIENT_STATUS':
        return 'Status do Paciente Alterado'
      default:
        return operation
    }
  }
}
