import axiosIns from '@/libs/axios'
import utilsService from './utilsService'

const controller = 'patient'

export default {
  async getAll(params) {
    return axiosIns.get(`${controller}?${utilsService.getQueryParams(params)}`).then(res => res.data.content)
  },
  async getById(id) {
    return axiosIns.get(`${controller}/${id}`).then(res => res.data)
  },
  async getMedicalRecordsByUserId(id) {
    return axiosIns.get(`${controller}/medical-records/${id}`).then(res => res.data)
  },
  async update(obj) {
    return axiosIns.put(controller, obj).then(res => res.data)
  },
  async create(req) {
    return axiosIns.post(controller, req).then(res => res.data)
  },
}
